import { createAxiosService } from '@/services/common/create-axios.service';
import {
  RsaKeysInterface,
  HttpResponseInterface,
  AuthInfo,
  DormantInfo
} from '@/models';
import axios from 'axios';
import router from '@/router';
import { sendNclick } from '@/services';

const axiosService = createAxiosService();
const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

interface DataInfo {
  data: Object;
  result: Object;
}

/**
 * RSA Keys 요청 api
 * @param sessionType 요청 타입
 * LOGIN / SIGN_UP / ID_CHANGE / CHANGE_PASSWORD / CHECK_PASSWORD / RESET_PASSWORD
 */
const getRsaKeys = (sessionType: string): Promise<RsaKeysInterface> => {
  return new Promise<RsaKeysInterface>((resolve, reject) => {
    const url = `/member/rsa/${sessionType}`;
    return axiosService
      .get(url)
      .then((res) => {
        const { data } = res;
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * login
 * @param auth user info
const login = auth => {
  const params = new URLSearchParams();
  params.append('email', auth.email);
  params.append('sessionKey', auth.sessionKey);
  params.append('rsaEncryptedString', auth.rsaEncryptedString);
  return axiosService.post('member/signin', params, config);
};
 */

const getIP = (): Promise<string> => {
  return new Promise<string>((resolve) => {
    // Gets IP address from 3rd service
    const url = 'https://api.ipify.org/?format=json';
    //const url = 'https://jsonip.com/';
    return axios
      .get(url, {timeout: 350})
      .then((res) => {
        try {
          const {
            data: { ip }
          } = res;
          resolve(ip);
        } catch (error) {
          resolve('0.0.0.0');
        }
      })
      .catch(() => {
        // blocked by CORS
        resolve('0.0.0.0');
      });
  });
};

// const getIP = async (): Promise<string> => {
//   const fetchIPFromIpify = () => {
//     return axios.get('https://api.ipify.org/?format=json', { timeout: 300 })
//       .then((res) => {
//         try {
//           const {
//             data: { ip }
//           } = res;
//           return ip;
//         } catch (error) {
//           return '0.0.0.0';
//         }
//       });
//   };

//   const fetchIPFromJsonIp = () => {
//     return axios.get('https://jsonip.com', { timeout: 300 })
//       .then((res) => {
//         try {
//           const {
//             data: { ip }
//           } = res;
//           return ip;
//         } catch (error) {
//           return '0.0.0.0';
//         }
//       });
//   };
  
//   const ip = await Promise.race([
//     fetchIPFromIpify(),
//     fetchIPFromJsonIp()
//   ]);
//   return ip;
// };

const getUserInfo = async (): Promise<Record<string, Object>> => {
  const url = `/member/profile`;
  return await axiosService.get(url);
};

/** 로그인 */
const login = (auth: AuthInfo): Promise<DataInfo> => {
  return new Promise<DataInfo>((resolve, reject) => {
    const url = '/member/signin';
    const params = new URLSearchParams();
    params.append('email', auth.email);
    params.append('sessionKey', auth.sessionKey);
    params.append('rsaEncryptedString', auth.rsaEncryptedString);
    if (auth.stayLogged) {
      params.append('stayLogged', String(auth.stayLogged));
    }
    return axiosService
      .post(url, params, config)
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** 약관 동의 및 휴대폰 인증 여부 */
const foregrounded = (): Promise<DataInfo> => {
  return new Promise<DataInfo>((resolve) => {
    const url = '/app/foregrounded';
    return axiosService.post(url).then((res) => {
      const { data } = res;
      resolve(data);
    });
  });
};

/** 인증 - send:이메일, check:코드 */
const certificateEmail = ({
  email,
  code
}: {
  email: string;
  code: number;
}): Promise<DataInfo> => {
  const url = `/member/${code ? 'check' : 'send'}/verify/token`;
  const bodyFormData = new FormData();
  bodyFormData.append('email', email);
  if (code) bodyFormData.append('token', String(code));
  return axiosService.put(url, bodyFormData, config).then((res) => {
    const { data } = res;
    return data;
  });
};

/** 신규약관동의(=휴대폰번호 등록) */
const memberConsent = (param): Promise<DataInfo> => {
  const url = `/member/consent`;
  const form = new FormData();
  if (param.phone && param.phone.length > 0)
    form.append('cellPhone', param.phone.toString());
  form.append('termsOfUse', param.termsAgreeYn ? 'Y' : 'N');
  form.append('privacyPolicy', param.termsPrivacyYn ? 'Y' : 'N');
  // form.append('legalAge', param.termsAge14Yn ? 'Y' : 'N'); // 프론트에서 체크만 서버에서 관란 안 함.
  form.append(
    'allowedMarketingNotification',
    param.termsMarketingYn ? 'Y' : 'N'
  );
  return axiosService.put(url, form, config).then((res) => {
    const { data } = res;
    return data;
  });
};

/** 회원가입 */
const signup = (auth: AuthInfo, isKo: boolean): Promise<DataInfo> => {
  return new Promise<DataInfo>((resolve, reject) => {
    const url = '/member/signup';
    const params = new URLSearchParams();
    params.append('email', auth.email);
    params.append(
      'nickname',
      auth.nickname ? auth.nickname : auth.nickname.split('@')[0]
    );
    params.append('sessionKey', auth.sessionKey);
    params.append('rsaEncryptedString', auth.rsaEncryptedString);
    if (isKo) {
      params.append('cellPhone', auth.cellPhone);
      params.append('termsOfUse', auth.termsOfUse);
      params.append('privacyCollectionAndUse', auth.privacyPolicy);
      // params.append('legalAge', auth.legalAge); // 프론트에서 체크만 서버에서 관란 안 함.
      params.append(
        'allowedMarketingNotification',
        auth.allowedMarketingNotification
      );
    }
    return axiosService
      .put(url, params, config)
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** 비밀번호찾기 */
const findPassword = async (email: string): Promise<DataInfo> => {
  const url = `/member/profile/password?email=${email}`;
  return await axiosService.delete(url, config).then((res) => {
    const { data } = res;
    return data;
  });
};

/** 비밀번호초기화 */
const resetPassword = async (auth: AuthInfo): Promise<DataInfo> => {
  const url = `/member/profile/password/reset`;
  const bodyFormData = new FormData();
  bodyFormData.append('token', auth.token);
  bodyFormData.append('sessionKey', auth.sessionKey);
  bodyFormData.append('rsaEncryptedString', auth.rsaEncryptedString);
  return axiosService.post(url, bodyFormData, config).then((res) => {
    const { data } = res;
    return data;
  });
};

/** 비밀번호변경 */
const changePassword = async (auth: AuthInfo): Promise<DataInfo> => {
  const url = `/member/profile/password`;
  const params = new URLSearchParams();
  params.append('sessionKey', auth.sessionKey);
  params.append('rsaEncryptedString', auth.rsaEncryptedString);
  return axiosService.put(url, params, config).then((res) => {
    const { data } = res;
    return data;
  });
};

/** 로그아웃 */
const logout = async (): Promise<DataInfo> => {
  const url = `/member/signout`;
  const res = await axiosService.post(url);
  const { data } = res;
  sendNclick(router.currentRoute.path, 'account.signout', '', '', '');
  return data;
};

/** 탈퇴 */
const accountDelete = async (
  category: string | null,
  message: string | null
): Promise<DataInfo> => {
  const url = `/member/${category}`;
  const res = await axiosService.delete(url, { params: { reason: message } });
  localStorage.removeItem('currentUser');
  const { data } = res;
  return data;
};

/** 휴면계정조회 */
const checkDormantPassword = (auth: AuthInfo): Promise<DataInfo> => {
  return new Promise<DataInfo>((resolve, reject) => {
    const url = '/status/check-dormant-password';
    const params = new URLSearchParams();
    params.append('email', auth.email);
    params.append('sessionKey', auth.sessionKey);
    params.append('rsaEncryptedString', auth.rsaEncryptedString);
    return axiosService
      .post(url, params, config)
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** 휴면해제 */
const dormantSwitch = async (
  dormantInfo: DormantInfo
): Promise<HttpResponseInterface> => {
  const url = `/status/switch-dormant-to-member`;
  const params = new URLSearchParams();
  params.append('neoIdUid', dormantInfo.neoIdUid);
  params.append('responseTokenType', 'email_id');
  params.append('tokenTypeHint', dormantInfo.tokenTypeHint);
  const res = await axiosService.post(url, params, config);
  const { data } = res;
  return data;
};

/** 휴면상태 */
const memberSwitch = async (): Promise<HttpResponseInterface> => {
  const url = `/status/switch-member-to-dormant`;
  const res = await axiosService.post(url);
  const { data } = res;
  return data;
};

/** 비밀번호 다음에 변경하기 */
const passwordExpiration = async (): Promise<HttpResponseInterface> => {
  const url = `/member/profile/password-expiration`;
  const res = await axiosService.put(url);
  const { data } = res;
  return data;
};

const AuthService = {
  getRsaKeys,
  getIP,
  getUserInfo,
  login,
  checkDormantPassword,
  foregrounded,
  memberConsent,
  certificateEmail,
  signup,
  findPassword,
  resetPassword,
  changePassword,
  logout,
  accountDelete,
  dormantSwitch,
  memberSwitch,
  passwordExpiration
};

export { AuthService };
